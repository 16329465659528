<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="150px">
      <el-container>
        <!-- 左 -->
        <el-aside class="left_box" width="300px">
          <el-input 
            placeholder="请输入关键词搜索"
            suffix-icon="el-icon-search"
            @input="dataSearchBlur"
            v-model="searchVal">
          </el-input>
          <div class="left_nav" v-loading="leftLoading">
            <div class="left_item" v-for="(item,index) in leftList" :key="index">
              <div class="item_title" @click="tagUnfoldFn(index)">
                <i v-if="item.isShow" class="el-icon-caret-bottom"></i>
                <i v-else class="el-icon-caret-right"></i>
                <div>{{item.tagName}}({{ item.conditions.length }})</div>
              </div>
              <div class="item_ele" v-if="item.isShow">
                <div class="left_ele" v-for="(ele,eleIndex) in item.conditions" :key="eleIndex" @click="leftTagSelFn(ele)">
                  <i class="el-icon-plus"></i>
                  <div>{{ ele.conditionName }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
        <!-- 中 -->
        <el-main class="body_box">
          <div class="crowd_box" v-for="(item,index) in formData.crowdList" :key="index">
            <div :class="index==crowselIndex?'crowd_item crowd_active':'crowd_item'" @click="crowdSelFn(index)">
              <div class="crowd_line" v-if="formData.crowdList.length>1">
                <div class="line_dot" v-if="item.preOperationType == 1">
                  <i class="el-icon-connection"></i>
                  <div>交集</div>
                </div>
                <div class="line_dot" v-if="item.preOperationType == 2">
                  <i class="el-icon-circle-plus-outline"></i>
                  <div>并集</div>
                </div>
                <div class="line_dot" v-if="item.preOperationType == 3">
                  <i class="el-icon-remove-outline"></i>
                  <div>差集</div>
                </div>
                <div class="line"></div>
              </div>
              <div class="item_head">
                <div class="title_box">
                  <i class="el-icon-delete" @click="delectCrowdFn(index)"></i>
                  <div class="title">人群池{{ index+1 }}</div>
                </div>
                <el-radio-group v-model="item.operationType" size="mini">
                  <el-radio-button label="1">池内取交集</el-radio-button>
                  <el-radio-button label="2">池内取并集</el-radio-button>
                </el-radio-group>
              </div>
              <div class="crowd_form" v-for="(ele, eleIndex) in item.condition" :key="eleIndex">
                <div class="crowd_form_title">
                  <i class="el-icon-delete" @click="delectCrowdFormFn(index,eleIndex)"></i>
                  <div>{{ ele.conditionName }}</div>
                </div>
                <el-form-item
                  class="form_item"
                  :label="key.name+'：'"
                  :prop="`crowdList.${index}.condition.${eleIndex}.attr.${keyIndex}.${key.field}`"
                  :rules="ruleFn(key)"
                  v-for="(key,keyIndex) in ele.attr"
                  :key="keyIndex">

                  <el-radio-group v-if="key.type=='radio'" v-model="key[key.field]">
                    <el-radio v-for="(sel, selIndex) in key.value" :key="selIndex" :label="sel.value">{{ sel.name }}</el-radio>
                  </el-radio-group>

                  <el-input v-if="key.type=='input'" v-model="key[key.field]"></el-input>

                  <el-select v-if="key.type=='select'&&key.field!='city'" v-model="key[key.field]" :multiple="key.multipleChoice==1" filterable clearable placeholder="请选择">
                    <el-option
                      v-for="(sel, selIndex) in key.value"
                      :key="selIndex"
                      :label="sel.name"
                      :value="sel.value">
                    </el-option>
                  </el-select>
                  <el-select v-if="key.type=='select'&&key.field=='city'" v-model="key[key.field]" :multiple="key.multipleChoice==1" filterable clearable placeholder="请选择">
                    <el-option-group
                      v-for="group in cityList"
                      :key="group.initials"
                      :label="group.initials">
                      <el-option
                        v-for="(item,index) in group.cityList"
                        :key="index"
                        :label="item.areaName"
                        :value="item.areaCode">
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <el-radio-group v-model="key['dateRadio']" @change="dateRadioChange(key,index,eleIndex,keyIndex)" v-if="key.type=='dateRang'">
                    <el-radio :label="1">范围</el-radio>
                    <el-radio :label="2">至今</el-radio>
                  </el-radio-group>
                  <el-date-picker
                    v-if="key.type=='dateRang'&&key['dateRadio']==1"
                    v-model="key[key.field]"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  <div v-if="key.type=='dateRang'&&key['dateRadio']==2">
                    <el-date-picker
                      @change="(e)=>dateChange(e,key,index,eleIndex,keyIndex)"
                      v-model="key['dateRadioVal']"
                      type="date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      placeholder="选择日期">
                    </el-date-picker>
                    -至今
                  </div>
                </el-form-item>
              </div>
            </div>
          </div>
          
          <el-dropdown trigger="click" style="width: 100%;" @command="addCrowdFn" placement="bottom">
            <el-button class="add_crowd" icon="el-icon-plus">添加人群池</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">交集</el-dropdown-item>
              <el-dropdown-item command="2">并集</el-dropdown-item>
              <el-dropdown-item command="3">差集</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-main>
        <!-- 右 -->
        <el-aside class="right_box" width="240px">
          <div class="right_title">
            <div>覆盖人群</div>
          </div>
          <div class="right_refresh">
            <div class="refresh">
              <div>{{ upadtaObj.coverNum>=0?upadtaObj.coverNum:'未更新' }}</div>
              <i class="el-icon-refresh" @click="updataCrowdFn"></i>
            </div>
            <div class="time">更新时间：{{ upadtaObj.updateTime || '未更新' }}</div>
          </div>
          <div class="right_crowd_name">
            <div>人群名称</div>
            <el-form-item label-width="0" prop="crowdName" :rules="{ required: true, message: `请输入人群名称`, trigger: 'blur' }">
              <el-input v-model="formData.crowdName" maxlength="16" show-word-limit placeholder="请输入人群名称"></el-input>
            </el-form-item>
          </div>
        </el-aside>
      </el-container>
    </el-form>
    <div class="page_btn">
      <el-button @click="crowdBack">返回</el-button>
      <el-button type="primary" @click="crowdSave">保存</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        searchVal: '',
        leftList: [],
        formData: {
          crowdName: '',                  //人群名称
          crowdList: [
            {operationType: 1, condition: [], preOperationType: ''}
          ]
        },
        formRules: {},
        // crowdList: [
        //   {operationType: 1, condition: [], preOperationType: ''}
        // ],
        crowselIndex: 0,                  //人群池所选角标
        leftLoading: false,               //左侧标签加载状态
        upadtaObj: {},                    //更新后的人群数据
        cityList: [],                     //城市列表
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "人群管理", path: "/crowdManagement/list" },
        {
          name: this.pageTitle
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dateRadioChange(key,index,eleIndex,keyIndex){
      let crowdList = JSON.parse(JSON.stringify(this.formData.crowdList))
      crowdList[index].condition[eleIndex].attr[keyIndex]['dateRadioVal'] = '';
      crowdList[index].condition[eleIndex].attr[keyIndex][key.field] = [];
      this.formData.crowdList = crowdList;
    },
    dateChange(e,key,index,eleIndex,keyIndex){
      let crowdList = JSON.parse(JSON.stringify(this.formData.crowdList))
      crowdList[index].condition[eleIndex].attr[keyIndex][key.field] = [e,'至今'];
      this.formData.crowdList = crowdList;
    },
    ruleFn(row){
      let rule = {};
      // 判断 row 是否包含 required 字段，并且其值不为空或 undefined
      let isRequired = (row.required !== undefined && row.required !== null)
        ? (row.required === 'true')
        : true;
      if(row.type == 'input'){
        rule = { required: isRequired, message: `请输入${row.name}`, trigger: 'blur' }
      }else{
        rule = { required: isRequired, message: `请选择${row.name}`, trigger: 'change' }
      }
      return rule
    },
    // 关键词搜索
    dataSearchBlur(e){
      let oldLeftList = JSON.parse(JSON.stringify(this.oldLeftList));
      let leftList = JSON.parse(JSON.stringify(this.leftList));
      leftList = oldLeftList.filter(item => {
        item.conditions = item.conditions.filter(ele => {
          if(ele.conditionName.indexOf(e)!=-1){
            return ele
          }
        })
        if(item.conditions.length>0){
          return item
        }
      })
      this.leftList = leftList;
    },
    // 添加人群池
    addCrowdFn(e){
      this.formData.crowdList.push({operationType: 1, condition: [], preOperationType: e});
      this.crowselIndex = this.formData.crowdList.length-1;
    },
    // 人群池选中
    crowdSelFn(index){
      this.crowselIndex = index;
    },
    
    // 重置搜索数据
    resetSearchData(e) {

    },
    // 左侧标签展开/收起
    tagUnfoldFn(index){
      this.leftList[index].isShow = !this.leftList[index].isShow;
    },

    // 左侧标签选择添加人群池
    leftTagSelFn(row){
      let condition = this.formData.crowdList[this.crowselIndex].condition || [];
      let isExist = condition.some(item => {
        if(row.conditionNo == item.conditionNo){
          return true
        }
      })
      if(isExist&&row.maximum<=1){
        this.$message.warning('已存在当前条件')
      }else{
        this.formData.crowdList[this.crowselIndex].condition.push(JSON.parse(JSON.stringify(row)))
      }
    },
    // 删除人群池
    delectCrowdFn(index){
      if(this.formData.crowdList.length<=1){
        this.$message.warning('无法删除，至少保留一个人群池')
        return
      }
      this.$confirm('是否删除此人群池?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.crowdList.splice(index,1);
        this.formData.crowdList[0].preOperationType = '';
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 删除人群池已添加标签条件
    delectCrowdFormFn(index,eleIndex){
      this.$confirm('是否删除此条件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.crowdList[index].condition.splice(eleIndex,1);
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // 更新覆盖人群
    updataCrowdFn(){
      let crowdList = JSON.parse(JSON.stringify(this.formData.crowdList));
      crowdList = crowdList.map(item => {
        item.condition.forEach(ele => {
          ele.attr.map(key => {
            key.value = ''
            return key
          })
        })
        return item
      })
      let isNext = crowdList.some(item => {
        return item.condition.length>0
      })
      if(!isNext){
        this.$message.warning('请至少在人群池中添加一项标签')
        return
      }
      this.$store.dispatch('management/crowdManagementCount',{
        singlePool: crowdList,
        ...this.formData
      }).then(res => {
        this.upadtaObj = res.data;
        this.$message.success('更新成功！');
      })
    },

    // 人群保存
    crowdSave(){
      let crowdList = JSON.parse(JSON.stringify(this.formData.crowdList));
      crowdList = crowdList.map(item => {
        item.condition.forEach(ele => {
          ele.attr.map(key => {
            key.value = ''
            return key
          })
        })
        return item
      })
      let isNext = crowdList.some(item => {
        return item.condition.length>0
      })
      if(!isNext){
        this.$message.warning('请至少在人群池中添加一项标签')
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$store.dispatch('management/crowdManagementSave',{
            singlePool: crowdList,
            ...this.formData
          });
          this.$message.success("添加人群包任务后台运行中，请稍后刷新查看");
          this.$router.back();
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // 返回
    crowdBack(){
      this.$router.back();
    },

    // 获取左侧标签
    crowdManagementTag(){
      this.leftLoading = true;
      this.$store.dispatch('management/crowdManagementTag',{}).then(res => {
        this.leftList = res.data.map(item => {
          item.isShow = true;
          return item;
        })
        this.oldLeftList = JSON.parse(JSON.stringify(this.leftList))
        this.leftLoading = false;
      })
    },
    // 获取城市列表
    getCityData(){
      this.$store.dispatch('management/crowdManagementCityList',{}).then(res => {
        this.cityList = res.data;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取左侧标签
    this.crowdManagementTag();
    // 获取城市列表
    this.getCityData();
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "crowdManagementAdd";
    this.pageTitle = this.$route.meta.title;
    if (!this.isAdd) {
      // this.getPackageInfo(this.$route.params.id);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;

  }
  .left_box{
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    .left_nav{
      margin-top: 20px;
      .left_item{
        .item_title{
          cursor: pointer;
          display: flex;
          align-items: center;
          margin: 10px 0;
          font-size: 14px;
          font-weight: bold;
          i{
            font-size: 18px;
            font-weight: bold;
          }
        }
        .item_ele{
          overflow: hidden;
          box-sizing: border-box;
          margin-top: -10px;
          .left_ele{
            width: fit-content;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 10px;
            i{
              font-weight: bold;
              margin-right: 5px;
              color: #4e93f7;
            }
          }
        }
      }
    }
  }
  .body_box{
    background: #fff;
    padding-left: 50px;
    .crowd_box{
      // padding-bottom: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      position: relative;
      .crowd_item{
        background: #f5f5f5;
        padding: 20px;
        box-sizing: border-box;
        border: 2px solid #f5f5f5;
        .item_head{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title_box{
            display: flex;
            align-items: center;
            >i{
              font-size: 18px;
              cursor: pointer;
            }
            .title{
              font-size: 18px;
              font-weight: bold;
              margin-left: 10px;
            }
          }
        }
      }
      .crowd_active{
        border: 2px solid #4e93f7;
      }
      .crowd_form{
        margin-top: 20px;
        .crowd_form_title{
          font-weight: bold;
          margin-left: 20px;
          display: flex;
          align-items: center;
          i{
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
      .crowd_line{
        position: absolute;
        top: 0;
        left: -40px;
        width: 30px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .line{
          width: 1px;
          height: 100%;
          background: #ddd;
        }
        .line_dot{
          position: absolute;
          top: -24px;
          display: flex;
          align-items: center;
          font-size: 10px;
          background: #fff;
          padding: 8px 0;
          box-sizing: border-box;
          color: #4e93f7;
        }
      }
    }
    .add_crowd{
      width: 100%;
      margin-top: 20px;
      color: #4e93f7;
    }
  }
  .right_box{
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    .right_title{
      font-size: 18px;
      font-weight: bold;
    }
    .right_refresh{
      margin-top: 20px;
      .refresh{
        cursor: pointer;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
        i{
          font-weight: bold;
          color: #4e93f7;
          margin-left: 3px;
        }
      }
      .time{
        color: #999;
        font-size: 12px;
      }
    }
    .right_crowd_name{
      margin-top: 20px;
      font-size: 15px;
      font-weight: bold;
      >div:nth-child(1){
        margin-bottom: 5px;
      }
    }
  }
  .page_btn{
    text-align: center;
    margin-top: 20px;
    .el-button{
      width: 120px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}
  ::v-deep .el-select{
    width: 100%;
  }
</style>
